<template>
	<div>
		<CRow>
			<div class="col-md-12">
				<div role="group" class="form-group">
					<label class=""> Name </label><br />
					<span class="h5">{{ rowData.name }}</span>
				</div>
			</div>
		</CRow>
		<CRow>
			<div class="col-md-2">
				<div role="group" class="form-group">
					<label class=""> Discount </label><br />
					<span class="h5">{{ rowData.discount }}</span>
				</div>
			</div>
			<div class="col-md-4">
				<div role="group" class="form-group">
					<label class=""> BTE </label><br />
					<span class="h5">{{ rowData.bte }}</span>
				</div>
			</div>
			<div class="col-md-4">
				<div role="group" class="form-group">
					<label class=""> Active </label><br />
					<span class="h5">{{ rowData.active }}</span>
				</div>
			</div>
		</CRow>
		<CRow>
			<div class="col-md-6">
				<div role="group" class="form-group">
					<label class=""> Start Date </label><br />
					<span class="h5">{{ rowData.start }}</span>
				</div>
			</div>
			<div class="col-md-6">
				<div role="group" class="form-group">
					<label class=""> End Date </label><br />
					<span class="h5">{{ rowData.end }}</span>
				</div>
			</div>
		</CRow>
		<CRow>
			<div class="col-md-6">
				<div role="group" class="form-group">
					<label class=""> Condition </label><br />
					<span class="h5">{{ rowData.condition }}</span>
				</div>
			</div>
		</CRow>
	</div>
</template>

<script>
export default {
	props: {
		rowData: {
			type: Object,
			required: true,
		},
		rowIndex: {
			type: Number,
		},
	},
};
</script>
